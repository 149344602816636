$ff-text: ibm-plex-sans, Arial, Helvetica, sans-serif;
$ff-title: ibm-plex-mono, Arial, Helvetica, sans-serif;
$ff-accent: futura-pt, Arial, Helvetica, sans-serif;;

$trs: all 0.3s ease-in;
$cubic: cubic-bezier(0.77, 0, 0.175, 0.75);
$trs-line: height 1.5s $cubic 1.5s;

// colors
$green: #7a917f;
$dark: #494b4d;
$orange: #e58947;
$navy: #19304b;
$blue: #589fd6;
$peach: #ffddc4;
$light: #ffffff;
$light-green: #d3d080;
$light-blue: #cddde3;
$light-grey: #e6e6e6;
$dark-green: #4d6652;

$red: #ee3325;

$gap: 36px;
$big-gap: 62px;