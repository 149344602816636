@import '1-vars';
@import '2-mixins';


@include lg {

  .banner + .page-header:not(.scrolled--visible) {
    top: 47px;
  }
  .burger {
    display: none;
  }
  .page-header {
    padding-top: 18px;
    top: 0;

    &__logo {
      max-width: 110px;
    }

    &__menu {
      margin-bottom: 12px;
      max-width: calc(100% - 110px);
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
  .page-header.scrolled--visible {
    .main-nav > ul > li {
      padding: 15px 0;

      & > ul {
        top: 73px;
      }
    }
  }
  .main-nav {
    & > ul {
      display: flex;
      align-items: center;

      & > li {
        margin: 0 16px;
        position: relative;
        padding: 30px 0;
        transition: $trs;

        & > a {
          white-space: nowrap;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            display: inline-block;
            height: 1px;
            transition: all .3s ease;
            background-color: $orange;
            transform-origin: 50% 50%;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            right: auto;
          }
        }

        &.current-menu-item {
          & > a:before {
            content: "";
            position: absolute;
            bottom: 0;
            display: inline-block;
            height: 1px;
            transition: all .3s ease;
            background-color: #fff;
            transform-origin: 50% 50%;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &.opened {
          & > ul {
            max-height: 100vh;
            opacity: 1;
            overflow: initial;
            padding-top: 40px;
            padding-bottom: 22px;

            &:before {
              content: '';
              display: block;
              width: 30px;
              height: 30px;
              background-color: $navy;
              transform: rotate(45deg);
              position: absolute;
              top: -15px;
              left: 35px;
            }
          }
        }

        &:last-child.opened {
          & > ul {
            right: 0;
            &:before {
              right: 35px;
              left: auto;
            }
          }
        }

        & > ul {
          position: absolute;
          top: 88px;
          font-size: 18px;
          line-height: 1.3;
          max-height: 0;
          overflow: hidden;
          padding: 0 36px;

          li {
            padding-bottom: 3px;
            margin-bottom: 16px;
          }

          & > .sub-menu__title {
            display: none;
          }

          & > .menu-item-has-children {
            &.opened {
              background-position: 96% 50%;

              ul {
                max-width: 150%;
                bottom: auto;
                min-height: 100%;
              }
            }

            ul {
              position: absolute;
              z-index: -1;
              //max-width: 0;
              left: 100%;

              &.left-sided {
                left: auto;
                right: 100%;
              }

              li.sub-menu__title {
                text-transform: uppercase;
                padding-left: 0;
                background: none;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }

    .search {
      display: none;
    }
  }

  .search {
    margin-left: 74px;

    &.opened .search__input {
      width: 272px;
      max-width: 272px;
    }

    &__input {
      width: 32px;
    }

    input {
      padding: 10px 40px 7px 16px;
    }

    &.opened {
      margin-left: 17px;
    }
  }

  .hero {
    height: 770px;
    padding-top: 40px;
    margin-bottom: 233px;
    padding-left: 40px;

    &--without-slider {
      margin-bottom: 183px;
    }

    &--small {
      min-height: 420px;
      height: auto;
      margin-bottom: 0;

      .hero-slide {
        padding-top: 165px;
        padding-bottom: 9rem;
      }
    }

    &--contact {
      min-height: 444px;
      height: auto;

      .hero-slide {
        padding-bottom: 0;
      }
    }

    &--volunteer {
      .hero-slide {
        padding-bottom: 13rem;
      }

      .hero-slide__title {
        max-width: 730px;
        line-height: normal;
      }
    }

  }

  .hero__slider {
    height: calc(100% + 85px);
  }

  .hero__content {
    height: calc(100% + 50px);
  }

  .hero-slide {
    padding: 80px 140px 0;

    &__text {
      font-size: 2rem;
      line-height: 1.4;
      margin-bottom: 62px;
    }
  }
  .list--short {
    column-count: 2;
  }
  .living {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &__image {
      flex-basis: 530px;
      flex-shrink: 0;
      margin-bottom: 9rem;
    }

    &__content {
      order: -1;
      flex-basis: 73%;
      margin-right: -23%;
      padding: 0 40px 47px 70px;
    }

    &__title {
      padding: 72px 40px 40px 70px;
      flex-basis: 73%;
      order: -1;
    }

    &__text {
      padding-right: 39%;
      margin-bottom: 40px;
      font-size: 2.4rem;
      line-height: 1.66;

      p:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  .work-item {
    display: flex;
    max-width: 1080px;
    margin: 0 auto 15rem;

    &__image {
      flex-basis: 50%;
      flex-shrink: 0;
    }

    &__content {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 40px 60px;
    }

    &__title {
      letter-spacing: 12px;
    }

    &__text {
      font-size: 24px;
      line-height: 1.67;
      // margin-bottom: 37px;
    }

    &--right-image {
      .work-item__content {
        order: -1;
      }
    }

    &--right-image.work-item--transparent {
      .work-item__content {
        padding-left: 0;
      }
    }
  }
  .quote {
    margin-left: auto;
    margin-right: auto;
  }

}